<template>
    <div class="page-box">
        <div class="header">
            <van-nav-bar
            :title="$t('mining.title')"
            left-arrow
            @click-left="onClickLeft"
            >
            </van-nav-bar>
        </div>
        <!-- <div class="maincontent"></div> -->
        <div class="order-null">
            <van-image width="2.34rem" height="2.88rem" :src="require('../../assets/img/order-null.png')" />
            <div style="font-size: 0.75rem;color: #8796AD;margin-top: 0.66rem;">No Records</div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {}
    },
    created() {
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .page-box{
    min-height: 100vh;
  }
  .maincontent {
    padding: 80px 0.94rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100vh;
  }
  .order-null{
    padding: 80px 0.94rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
</style>
  